// import '../../components/product-preview/product-preview.component.scss';
// import openPreview from '../../components/product-preview/product-preview.component.js';
import lazyLoad from './lazy.js'
import auth from './auth/auth'
import swiper from './swiper'
import initMask from './mask'
import scroll from './scroll'
import parallax from './parallax'
import rateInit from './rate'
import API from './api'
import $ from 'jquery'
import 'select2/dist/js/select2.full.js'
import Snackbar from 'node-snackbar'
import { formRollbackInit } from './formRollbackHandler'
import { filterButtonInit } from '../../components/catalog/catalog.component'
require('regenerator-runtime/runtime')

class App {
  constructor() {
    this.initTime = new Date()
    this.log(`Start`)
    this.API = API
    this.auth = auth
    this.isPreventClose = false
    scroll()
  }
  isTouch() {
    return window.matchMedia('(pointer: coarse)').matches
  }
  log(input) {
    let time = new Date() - this.initTime
    if (time > 1000) {
      time = (time / 1000).toFixed(2) + ' s'
    } else {
      time = time + ' ms'
    }
    console.log(`%c[${time}] %c${input}`, 'color: #F278A5', 'color: #78D5F2')
  }
  lazy(selector) {
    lazyLoad(selector).then(resolve => {
      this.log(`Lazy load init with '${resolve}' selector`)
    })
  }
  swiper(selector, options) {
    return swiper(selector, options).then(resolve => {
      this.log(`Swiper init with '${resolve.passedParams.el}' selector`)
      this.lazy(`${selector} .lozad-in-slider`)
      return resolve
    })
  }
  mask(el, options) {
    if (el && options) {
      return initMask(el, options).then(resolve => {
        this.log(`Accept mask ${resolve.masked.mask}`)
      })
    }
  }
  parallax(selector, options) {
    return parallax(selector, options).then(resolve => {
      this.log(`Parallax selector ${resolve}`)
    })
  }
  selectsInit() {
    $(document).ready(() => {
      $('select').each(function() {
        let isInit =
          !$(this).hasClass('select2-hidden-accessible') &&
          !$(this).hasClass('orderby') &&
          !$(this).hasClass('disable-selector') &&
          !$(this).hasClass('simple-select') &&
          !$(this).parents('.ginput_container_select').length > 0 &&
          !$(this).parents('.shipping-calculator-form').length > 0
        if (isInit) {
          $(this).select2({
            placeholder: $(this).data('placeholder')
              ? $(this).data('placeholder')
              : false,
            minimumResultsForSearch: Infinity,
            dropdownParent: $(this).parent()
          })
        }
      })
    })
  }
  productPreviewOpen() {
    // openPreview(id);
    console.warn('popup products removed')
  }
  rate() {
    rateInit()
  }

  snackbar() {
    document.addEventListener(
      'wpcf7submit',
      function(event) {
        let color = '#d84b37'
        if (event.detail.apiResponse.status === 'mail_sent') {
          color = '#43a047'
        }
        Snackbar.show({
          text: event.detail.apiResponse.message,
          pos: 'bottom-center',
          backgroundColor: color,
          textColor: '#ffffff',
          showAction: false,
          actionText: 'Close',
          duration: 5000,
          customClass: 'snackbar-theme-1'
        })
      },
      false
    )
  }

  updateAction(type) {
    this.log(`update action: ${type}`)
    if (type === 'filter') {
      this.lazy('.product-small img')
      this.selectsInit()
      filterButtonInit()
      rateInit()
    }
  }

  quantity() {
    const elements = document.querySelectorAll('.TOH-WP-Quantity')
    function createButton(icon, offset) {
      const button = document.createElement('button')
      button.type = 'button'
      button.innerHTML = `<i class="fal ${icon}"></i>`
      button.dataset.offset = offset
      return button
    }
    for (let el of elements) {
      const isActions = el.querySelector('.TOH-WP-Quantity__actions')
      if (!isActions) {
        const actions = document.createElement('div')
        actions.classList.add('TOH-WP-Quantity__actions')
        const buttons = document.createDocumentFragment()
        buttons.appendChild(createButton('fa-chevron-up', 1))
        buttons.appendChild(createButton('fa-chevron-down', -1))
        actions.appendChild(buttons)
        el.querySelector('.quantity').appendChild(actions)

        $(el).on('click', '.TOH-WP-Quantity__actions button', function() {
          const offset = parseInt($(this).data('offset'))
          const input = $(this)
            .parents('.TOH-WP-Quantity')
            .find('input')
          const value = parseInt(input.val())
          const newValue = value + offset
          if (newValue >= 1) {
            input[0].value = newValue
            input.val(newValue)
            input.trigger('change')
            input[0].dispatchEvent(new Event('change'))
            const updateButton = document.querySelector(
              'button[name="update_cart"]'
            )
            if (updateButton) {
              updateButton.disabled = false
            }
          }
        })
      }
    }
  }
  scrollTo(target) {
    if (typeof target === 'string') {
      target = document.querySelector(target)
    }
    const getOffsetTop = element => {
      let offsetTop = 0
      while (element) {
        offsetTop += element.offsetTop
        element = element.offsetParent
      }
      return offsetTop
    }
    if (target) {
      window.scrollTo({
        top:
          getOffsetTop(target) - document.querySelector('.header').offsetHeight,
        behavior: 'smooth'
      })
      return true
    } else {
      return false
    }
  }
  scrollHandler(event) {
    const target = event.target.dataset.scrollToQuery
    const isCurrent = this.scrollTo(target)
    if (isCurrent) {
      event.preventDefault()
    } else if (target) {
      localStorage.setItem('scroll-target', target)
    }
  }
  datePickersInit() {
    const pickersList = document.querySelectorAll('.datepicker-field')
    if (pickersList.length > 0) {
      import(/* webpackChunkName: "datepicker-js" */ 'Datepicker.js').then(
        ({ default: Datepicker }) => {
          pickersList.forEach(element => {
            try {
              new Datepicker(element, {
                serialize(date) {
                  return date.toLocaleDateString('en-US')
                }
              })
              element.addEventListener('keypress', event => {
                event.preventDefault()
              })
            } catch (e) {}
          })
        }
      )
    }
  }
  fileInputInit() {
    document.querySelectorAll('.vForm-file').forEach(element => {
      const input = element.querySelector('input[type="file"]')
      if (input) {
        input.addEventListener('change', event => {
          const file = event.target.files[0]
          if (file.size / 1024 / 100 > 5) {
            input.value = ''
            Snackbar.show({
              text: 'The file is too big (max. 5 Mb)',
              pos: 'bottom-right',
              backgroundColor: '#d84b37',
              textColor: '#ffffff',
              showAction: false,
              actionText: 'Close',
              duration: 5000,
              customClass: 'snackbar-theme-1'
            })
            return false
          }
          element.querySelector('.vForm-file__content').innerText = `${
            file && file.name ? file.name : 'Choose file'
          }`
        })
      } else {
        console.warn('input[type="file"] not found in .vForm-file')
      }
    })
  }
  selectsPlaceholderInit() {
    // document
    //   .querySelectorAll('.vForm-field select.simple-select')
    //   .forEach(select => {
    //     const option = document.createElement('option')
    //     option.disabled = true
    //     option.selected = true
    //     option.hidden = true
    //     option.value = ''
    //     option.textContent = 'Please select...'
    //     select.prepend(option)
    //     select.classList.add('select-placeholded')
    //     select.addEventListener('change', () => {
    //       if (select.value === '') {
    //         select.classList.add('select-placeholded')
    //       } else {
    //         select.classList.remove('select-placeholded')
    //       }
    //     })
    //   })
  }
  phoneMaskInit() {
    document.querySelectorAll('input[type="tel"]:not(#paytrace-card-number, #paytrace-card-cvc, #paytrace-card-expiry)').forEach(phone => {
      if (
        !phone.dataset.hasOwnProperty('phoneInit') &&
        phone.id !== 'billing_phone'
      ) {
        // phone.dataset.phoneInit = true
        app.mask(phone, {
          mask: '+{1}0000000000'
        })
      }
    })
  }
  subListsInit() {
    document.querySelectorAll('[data-sub-list]').forEach(item => {
      const targetName = item.dataset.subList
      const targetElement = document.querySelector(
        `[data-sub-list-content="${targetName}"]`
      )
      if (targetElement && targetElement.dataset.inited !== 'true') {
        targetElement.dataset.inited = 'true'
        item.addEventListener('click', () => {
          console.log(targetElement.style.display)
          if (targetElement.style.display === 'block') {
            targetElement.style.display = ''
          } else {
            targetElement.style.display = 'block'
          }
        })
      }
    })
  }
}
const app = new App()
if (document.querySelectorAll('.rate').length > 0) {
  app.rate()
}
app.snackbar()
app.quantity()
app.selectsInit()
app.datePickersInit()
app.fileInputInit()
app.selectsPlaceholderInit()
app.phoneMaskInit()
app.subListsInit()
formRollbackInit()
try {
  jQuery(function($) {
    $('.wpcf7-field-groups')
      .on('wpcf7-field-groups/change', function(event) {
        const lenght = $(event.target).find('.wpcf7-field-group').length
        $(event.target)
          .find('.wpcf7-field-group-add ')
          .attr('disabled', lenght >= 3)
        app.phoneMaskInit()
        app.datePickersInit()
        const $groups = $(this).find('.group-index')
        $groups.each(function() {
          $(this).text($groups.index(this) + 1)
        })
      })
      .trigger('wpcf7-field-groups/change')
  })
} catch (e) {}

window.addEventListener('beforeunload', event => {
  if (app.isPreventClose) {
    event.returnValue = 'You have unfinished changes!'
  }
})
document.querySelectorAll('[data-scroll-to-query]').forEach(link => {
  const newUrl = link.href.split('#')[0]
  if (newUrl) {
    link.href = newUrl
  }
  link.addEventListener('click', app.scrollHandler.bind(app))
})
document.addEventListener('DOMContentLoaded', () => {
  const target = localStorage.getItem('scroll-target')
  if (target) {
    app.scrollTo(target)
    localStorage.removeItem('scroll-target')
  }
})

window.$application = app
export default app
