import './product-row.component.scss'
import '../product-small/product-small.component'
import app from '../../helpers/js/app'

if (document.querySelector('.product-row .swiper-container')) {
  app.swiper('.product-row .swiper-container', {
    slidesPerView: 4,
    spaceBetween: 20,
    autoHeight: true,
    navigation: {
      prevEl: '.product-row__nav-prev',
      nextEl: '.product-row__nav-next'
    },
    breakpoints: {
      992: {
        slidesPerView: 3,
        spaceBetween: 20
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 8
      },
      480: {
        slidesPerView: 1,
        spaceBetween: 8
      }
    }
  })
}

const catNav = document.querySelector('.product-row__cats')
const catContainers = document.querySelectorAll('.product-row__cat')
function catNavHandler(target) {
  const cat = target.dataset.catName
  const parent = target.closest('.product-row')
  const link = parent.querySelector('.product-row__all')
  const linkMobile = parent.querySelector('.product-row__all-mobile')
  if (cat) {
    const active = catNav.querySelector('.active')
    if (active) active.classList.remove('active')
    target.classList.add('active')
    catContainers.forEach(container => {
      if (container.dataset.catName === cat) {
        container.dataset.state = 'showed'
      } else {
        container.dataset.state = 'hidden'
      }
    })
    if (link) {
      link.href = target.dataset.urlMore || '#'
    }
    if (linkMobile) {
      linkMobile.href = target.dataset.urlMore || '#'
    }
  }
}
if (catNav && catContainers.length > 0) {
  const initCat = catNav.querySelector('[data-cat-name]')
  if (initCat) {
    catNavHandler(initCat)
  }
  catNav.addEventListener('click', evt => {
    catNavHandler(evt.target)
  })
}
