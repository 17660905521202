import './cart-popup.component.scss'
import app from '../../helpers/js/app'

class CartPopup {
  constructor() {
    this.trigger = document.getElementById('cart-popup-trigger')
    this.popup = document.getElementById('cart-popup')
    this.state = 'closed'
    if (this.trigger && this.popup) {
      this.closeBtn = this.popup.querySelector('[data-cart-close]')
      this.init()
    } else {
      console.error('Cart popup undefined')
    }
  }
  changeState(state, toggle) {
    console.warn('change state: ', state)
    if (toggle) {
      this.state = this.state === 'closed' ? 'showed' : 'closed'
    } else {
      this.state = state
    }
    this.popup.dataset.cartPopupState = this.state
  }
  init() {
    this.trigger.addEventListener('click', (e) => {
      if (window.innerWidth >= 992) {
        e.preventDefault()
        this.changeState('showed', true)
      }
    })
    document.addEventListener('click', (e) => {
      if (e.target.closest('.cart-popup')) {
        return false
      }
      this.changeState('closed')
    })
    this.changeState(this.state)
    try {
      jQuery(document.body).on('removed_from_cart', () => {})
    } catch (e) {
      console.error(err)
    }
    document.addEventListener('click', (e) => {
      if (e.target.closest('.remove_from_cart_button')) {
        this.changeState('loading')
        setTimeout(() => {
          app.API.updateMiniCart().finally(() => {
            this.changeState('showed')
          })
        }, 3000)
        return false
      }
      if (e.target.closest('[data-cart-close]')) {
        e.preventDefault()
        this.changeState('closed')
      }
    })
  }
}

new CartPopup()
