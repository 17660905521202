import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import api from '../api.js'

class Auth {
  constructor() {
    this.currentPopup = null
    this.form = null
    this.handlers()
    this._isLoading = false
  }
  set isLoading(value) {
    if (this.form) {
      this.form.dataset.loading = value
    }
    this._isLoading = value
  }
  handlers() {
    document.addEventListener('click', evt => {
      const target = evt.target
      if (target.closest('.auth__close')) {
        this.closePopup()
      }
    })
  }
  renderError(errors) {
    if (this.form) {
      const $errors = this.form.querySelector('[data-errors]')
      $errors.innerHTML = errors || ''
    }
  }
  submit(event) {
    event.preventDefault()
    const form = event.target
    const data = new FormData(form)
    this.renderError()
    this.isLoading = true
    api
      .userLogin({
        login: data.get('log') || null,
        password: data.get('pwd') || null,
        ls: data.get('login-security') || null,
        remember: data.get('rememberme') || null
      })
      .then(response => {
        const data = response.data
        const preview = document.getElementById('productPreview')
        if (data.message && !data.error) {
          const temp = document.createElement('div')
          temp.innerHTML = data.message.personalizeImage
          preview.innerHTML = `<img src="${temp.querySelector('a').href}">`
        }
        if (!!data.error) {
          if (typeof data.message === 'object') {
            this.toggle('close')
            if (window._imprintFreeze) {
              window._imprintFreeze(data.message)
            }
          } else {
            if (data.message === 'Orders not Found') {
              this.toggle('close')
              if (window._imprintPrevent) {
                window._imprintPrevent()
              }
            } else {
              this.renderError(data.message)
            }
          }
        } else {
          window._wordPressIsAuth = true
          this.toggle('close')
        }
      })
      .finally(() => {
        this.isLoading = false
      })
  }
  openPopup() {
    try {
      this.template = document
        .getElementById('authPopupTemplate')
        .content.cloneNode(true)
    } catch (e) {
      throw new Error('auth popup template not found')
    }
    if (this.template && !this.currentPopup) {
      document.body.append(this.template)
      this.currentPopup = document.getElementById('authPopup')
      this.form = this.currentPopup.querySelector('form')
      if (this.form) {
        this.form.addEventListener('submit', event => {
          this.submit(event)
        })
      }
      if (this.currentPopup) {
        this.toggle('open')
      } else {
        throw new Error(`currentPopup is ${this.currentPopup}`)
      }
    }
  }
  closePopup() {
    if (this.currentPopup) {
      this.toggle('close')
      if (this.form) {
        this.form.removeEventListener('submit', Auth.submit)
        this.form = null
      }
    }
  }
  toggle(task) {
    if (task === 'open') {
      disableBodyScroll(this.currentPopup.querySelector('.auth__inner'))
    } else if (task === 'close') {
      enableBodyScroll(this.currentPopup.querySelector('.auth__inner'))
      this.currentPopup.remove()
      this.currentPopup = null
    }
  }
}

const auth = new Auth()
export default auth
