import app from '../app'
export async function updateRollbackItems(items, size) {
  if (items && items.length) {
    const updatedItems = []
    const updateItem = async (item, size) => {
      try {
        const updatedItem = await app.API.saveComponentPersonalization({
          id: item.id,
          size: size,
          color: item.color,
          quantity: 1,
          _color: item.personalizeResult?.color,
          _type: item.configuratorType,
          _isToothbrush: item.isToothbrush,
          _isComponent: item.isComponent
        })
        return { ...item, personalizePrices: updatedItem.data }
      } catch (e) {
        throw e
      }
    }
    for (let item of items) {
      if (item.personalizePrices && !item.isToothbrush) {
        updatedItems.push(updateItem(item, size))
      } else {
        updatedItems.push(item)
      }
    }
    return await Promise.all(updatedItems)
  }
}
