import { base64ImageToBlob } from '../imageToBlob'
import { base64StringToBlob } from 'blob-util'
import cloneDeep from 'clone-deep'

export function formatData(initData) {
  const data = cloneDeep(initData)
  const { items } = data
  const files = {}
  items.forEach(item => {
    if (item.personalizeResult) {
      const suffix = `${item.id}${item.parallelId ? '_' + item.parallelId : ''}`
      if (typeof item.personalizeResult.canvas === 'object') {
        files[`canvas_${suffix}`] = base64StringToBlob(
          btoa(JSON.stringify(item.personalizeResult.canvas)),
          'application/json'
        )
      } else if (typeof item.personalizeResult.canvas === 'string') {
        files[`canvas_${suffix}`] = false
      }
      files[`customImage_${suffix}`] = base64ImageToBlob(
        item.personalizeResult.customImage
      )
      files[`fullImage_${suffix}`] = base64ImageToBlob(
        item.personalizeResult.fullImage
      )
      files[`personImage_${suffix}`] = base64ImageToBlob(
        item.personalizeResult.base64Result
      )
      if (files[`customImage_${suffix}`]) {
        delete item.personalizeResult.customImage
      }
      if (files[`personImage_${suffix}`]) {
        delete item.personalizeResult.base64Result
      }
      if (item.personalize) {
        delete item.personalize
      }
      delete item.personalizeResult.fullImage
      delete item.personalizeResult.canvas
    }
  })
  data.items = items
  return {
    json: data,
    files
  }
}
