import SimpleParallax from 'simple-parallax-js';
export default function (selector, options = {
    delay: 1,
    scale: 1.25,
    orientation: 'down'
}) {
    return new Promise((resolve) => {
        new SimpleParallax(selector, options);
        resolve(selector);
    });
};