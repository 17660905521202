
import(/* webpackChunkName: "smoothscroll-polyfill" */ 'smoothscroll-polyfill').then(({ default: smoothscroll }) => { smoothscroll.polyfill(); });
const getOffsetTop = element => {
    let offsetTop = 0;
    while(element) {
        offsetTop += element.offsetTop;
        element = element.offsetParent;
    }
    return offsetTop;
}
export default function () {
    document.querySelectorAll('[data-scroll-to]').forEach((anchor) => {
        const targetSelector = document.querySelector(anchor.dataset.scrollTo);
        if (targetSelector) {
            anchor.addEventListener('click', function (event) {
                event.preventDefault();
                window.scrollTo({
                    top: getOffsetTop(targetSelector) - document.querySelector('.header').offsetHeight,
                    behavior: 'smooth'
                });
            }, false);
        }
    })
}