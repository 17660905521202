import { base64StringToBlob } from 'blob-util'
function isBase64(base64) {
  return base64 ? base64.match('base64,') !== null : false
}

export function base64ImageToBlob(base64 = '') {
  if (isBase64(base64)) {
    const body = base64.split('base64,')[1]
    const type = base64.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]
    return base64StringToBlob(body, type)
  } else {
    return false
  }
}
