import './hero-slider.component.scss'
import app from '../../helpers/js/app'

app.swiper('.hero-slider-swiper', {
  allowTouchMove: false,
  effect: 'fade',
  autoHeight: true,
  fadeEffect: {
    crossFade: true
  },
  autoplay: {
    delay: 5000
  },
  pagination: {
    el: '.hero-slider-bullets',
    type: 'bullets',
    clickable: true
  }
})

const scrollDown = document.querySelectorAll('.hero-slider-item__down button')
if (scrollDown.length > 0) {
  const getOffsetTop = element => {
    let offsetTop = 0
    while (element) {
      offsetTop += element.offsetTop
      element = element.offsetParent
    }
    return offsetTop
  }
  scrollDown.forEach(scrollDown => {
    scrollDown.addEventListener('click', () => {
      const target = scrollDown.closest('.hero-slider').nextElementSibling
      if (target) {
        window.scrollTo({
          top:
            getOffsetTop(target) -
            document.querySelector('.header').offsetHeight,
          behavior: 'smooth'
        })
      }
    })
  })
}
