import axios from 'axios'
import debounce from 'debounce-promise'
import { formatData } from './api-helpers/kit-bundle-format'
import { formatDataProduct } from './api-helpers/product-format'
import { updateRollbackItems } from './api-helpers/update-rollback-items'
require('regenerator-runtime/runtime')

function request(method = 'POST', url, data) {
  if (!method || !data) {
    throw new Error('URL or Data undefined')
  }
  const formData = new FormData()
  for (let prop in data) {
    let value =
      typeof data[prop] === 'object' ? JSON.stringify(data[prop]) : data[prop]
    value = encodeURIComponent(value)
    if (data[prop] instanceof Blob) {
      value = data[prop]
    }
    formData.append(prop, value)
  }
  return axios({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method,
    url,
    data: formData
  })
}

const debouncedRequest = debounce(request, 1000)

class API {
  constructor() {
    this.BASE = {
      URL: '/wp/wp-admin/admin-ajax.php'
    }
  }
  getProductFromId(id) {
    if (!id) {
      throw new Error('getProductFromId (id undefined)')
    }
    return request('POST', this.BASE.URL, {
      action: 'quickView',
      product_id: id
    })
  }

  saveProductPersonalization(
    data = {},
    productId,
    size,
    color,
    quantity,
    { isFinal, imprint, type } = {}
  ) {
    console.log({data, imprint, type})
    let submitData = {
      action: window.__EXCLUDED && isFinal ? 'updateCartItem' : 'configurator',
      excluded: window.__EXCLUDED || null,
      product_id: productId,
      quantity: quantity || 1,
      size: size,
      imprint: imprint || false,
      type: type || 'error: broken type',
      color: color
    }
    if (isFinal) {
      const { json, files } = formatDataProduct(data)
      submitData = {
        ...submitData,
        isFinal: true,
        json,
        ...files
      }
    } else {
      submitData = {
        ...submitData,
        isFinal: false,
        json: {
          type: data.type || type,
          color: data.color
        }
      }
    }
    return request('POST', this.BASE.URL, submitData)
  }
  saveComponentPersonalization({
    id,
    size,
    color,
    quantity,
    _color,
    _type,
    _isToothbrush,
    _isComponent,
    _isImprint
  }) {
    if (!_isToothbrush) {
      return request('POST', this.BASE.URL, {
        action: 'bundleConfigurator',
        product_id: id,
        size: size,
        color: color,
        quantity: quantity || 1,
        is_toothbrush: _isToothbrush,
        is_component: _isComponent,
        configurator_color: _color,
        configurator_type: _type,
        is_imprint: _isImprint || false
      })
    } else {
      return new Promise((resolve, reject) => {
        reject('toothbrush, prevent request')
      })
    }
  }
  getToothbrushesPersonalizationData(items) {
    return request('POST', this.BASE.URL, {
      action: 'toothFree',
      items
    })
  }
  saveKitBundle(type, id, excluded, data) {
    const { json, files } = formatData(data)
    // return false
    return request('POST', this.BASE.URL, {
      action: 'bundleProcessing',
      product_id: id,
      excluded: excluded,
      type: type,
      json: json,
      ...files
    })
  }
  getBuildKitComponents(productId, category) {
    return request('POST', this.BASE.URL, {
      action: 'buildKitComponents',
      product_id: productId,
      category: category
    })
  }
  userLogin(data) {
    return request('POST', this.BASE.URL, {
      action: 'pt_login_member',
      pt_user_login: data.login,
      pt_user_pass: data.password,
      pt_remember: data.remember,
      'login-security': data.ls
    })
  }
  getProductMeta(data) {
    return request('POST', this.BASE.URL, {
      action: 'previewImage',
      productId: data.id,
      color: data.color
    })
  }
  getCartItem(data) {
    return request('POST', this.BASE.URL, {
      action: 'cartControl',
      key: data.key,
      operation: data.action
    })
  }
  async getBuildYourKit(data) {
    try {
      const response = await request('POST', this.BASE.URL, {
        action: 'cartBuildItemUpdate',
        key: data.key,
        operation: data.action
      })
      if (
        response.data.general_rollback &&
        response.data.general_rollback.items
      ) {
        response.data.general_rollback.items = await updateRollbackItems(
          response.data.general_rollback.items,
          response.data.size.currentSize[0].name
        )
      } else {
        console.warn('general_data.items not found')
      }
      return response
    } catch (e) {
      console.error(e)
    }
  }
  async getBundle(data) {
    try {
      const response = await request('POST', this.BASE.URL, {
        action: 'cartControlBundle',
        key: data.key,
        operation: data.action
      })
      if (response.data.items) {
        response.data.items = await updateRollbackItems(
          response.data.items,
          response.data.size
        )
      } else {
        console.warn('items not found')
      }
      return response
    } catch (e) {
      return e
    }
  }

  getCustomBundle({ items, productId, type }) {
    return request('POST', this.BASE.URL, {
      action: 'getCustomBundle',
      category: type,
      product_id: productId,
      items: items
    })
  }
  updateMiniCart() {
    return request('POST', this.BASE.URL, {
      action: 'mode_theme_update_mini_cart'
    }).then(response => {
      const popupNode = document.getElementById('cart-popup')
      popupNode.innerHTML = response.data
      let length = 0
      popupNode.querySelectorAll('.cart-popup-item').forEach(item => {
        length += parseInt(item.dataset.quantity || 1)
      })
      document.querySelector('[data-cart-count]').innerText = length
      return response
    })
  }
  getBundlePreview(key) {
    return request('POST', this.BASE.URL, {
      action: 'bundlePreview',
      key: key
    })
  }

  getBadgeState(data) {
    return debouncedRequest('POST', this.BASE.URL, {
      action: 'badgeLogic',
      productID: data.id || null,
      selectSize: data.size || null,
      quantity: data.quantity || null,
      getAction: data.excluded.action || null,
      key: data.excluded.key || null
    })
  }

  addFreeSampleProduct(productId, isUpdate) {
    return request('POST', this.BASE.URL, {
      action: 'freeSamplesControl',
      product_id: productId,
      update_next: isUpdate
    })
  }

  saveUserImage({ userId, file }) {
    return request('POST', this.BASE.URL, {
      action: 'userControl',
      handler: 'customImage',
      userId: userId,
      image: file
    })
  }
}
const API_INSTANCE = new API()
export default API_INSTANCE
