import { base64ImageToBlob } from '../imageToBlob'
import { base64StringToBlob } from 'blob-util'
import cloneDeep from 'clone-deep'

export function formatDataProduct(initData) {
  const json = cloneDeep(initData)
  const files = {}

  files.customImage = base64ImageToBlob(json.customImage)
  files.fullImage = base64ImageToBlob(json.fullImage)
  files.personImage = base64ImageToBlob(json.base64Result)
  files.canvas = base64StringToBlob(
    btoa(JSON.stringify(json.canvas)),
    'application/json'
  )

  if (files.customImage) {
    delete json.customImage
  }
  if (files.personImage) {
    delete json.base64Result
  }
  delete json.fullImage
  delete json.canvas
  return { json, files }
}
