import './newsletter.component.scss'
//import app from '../../helpers/js/app';;

function formAfterSubmitHandler(event) {
  const form = event.target.querySelector('form')
  form.classList.remove('is-loading-submit')
  document.removeEventListener('wpcf7submit', formAfterSubmitHandler, false)
}
document.querySelectorAll('.newsletter').forEach(newsletter => {
  const form = newsletter.querySelector('form')
  form.addEventListener('submit', () => {
    form.classList.add('is-loading-submit')
    document.addEventListener('wpcf7submit', formAfterSubmitHandler, false)
  })
})
