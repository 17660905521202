// import './home.scss';
import './home.pug' // FIXME: hide me
import '../../helpers/styles/base.scss'
import '../../components/intro-mark/intro-mark.component'
import '../../components/header/header.component'
import '../../components/footer/footer.component'
import '../../components/hero-slider/hero-slider.component'
import '../../components/cats/cats.component'
import '../../components/promo-list/promo-list.component'
import '../../components/product-row/product-row.component'
import '../../components/newsletter/newsletter.component'
import '../../components/home-quote/home-quote.component'
import '../../components/home-who/home-who.component'
import '../../components/features/features.component'
import '../../components/home-ideas/home-ideas.component'
import app from '../../helpers/js/app'
app.lazy()
