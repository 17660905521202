import './home-quote.component.scss'
import app from '../../helpers/js/app'

app.swiper('.home-quote .swiper-container', {
  slidesPerView: 1,
  autoHeight: false,
  navigation: {
    prevEl: '.home-quote [data-prev]',
    nextEl: '.home-quote [data-next]'
  }
})
