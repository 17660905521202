import { generateUUID } from './generateUUID'

let currentForm = null
let fields = []
function formAfterSubmitHandler(event) {
  if (!currentForm) return
  const { target: form, detail } = event
  form.querySelectorAll('select').forEach(selectNode => {
    let { selectId } = selectNode.dataset
    if (fields[selectId]) {
      selectNode.value = fields[selectId]
    }
  })
  currentForm = null
  if (detail.apiResponse.status === 'mail_sent') {
    form
      .querySelectorAll('.vForm-file__content')
      .forEach(item => (item.textContent = 'Choose file'))
  }
}
function formBeforeSubmitHandler(event) {
  const { target: form } = event
  currentForm = form
  form.querySelectorAll('select').forEach(selectNode => {
    let { selectId } = selectNode.dataset
    if (!selectId) {
      selectNode.dataset.selectId = selectId = generateUUID()
    }
    if (selectNode.value && selectNode.value !== 'placeholder') {
      fields[selectId] = selectNode.value
    }
  })
}
export function formRollbackInit() {
  document.querySelectorAll('.wpcf7-form.vForm').forEach(form => {
    form.addEventListener('submit', formBeforeSubmitHandler, false)
  })
  document.addEventListener('wpcf7submit', formAfterSubmitHandler, false)
}
