import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const buttonsMore = document.querySelectorAll('.catalog-filter__more')
if (buttonsMore.length > 0) {
  buttonsMore.forEach(button => {
    button.addEventListener('click', function() {
      button
        .closest('.catalog-filter__group')
        .querySelector('.catalog-filter__groupItems')
        .classList.remove('catalog-filter__groupItems--hidden-more')
      button.style.display = 'none'
    })
  }, false)
}
const resetButton = document.querySelector('.catalog-filter__reset')
if (resetButton) {
  resetButton.addEventListener(
    'click',
    function() {
      const items = resetButton
        .closest('.catalog-filter')
        .querySelectorAll('input[type="checkbox"]')
      items.forEach(item => {
        item.checked = false
      })
    },
    false
  )
}

const wrapper = document.querySelector('.wrapper')
const filterButton = document.getElementById('filterButton')
const catalogFilter = document.getElementById('catalogFilter')
const catalogFilterClose = document.getElementById('filterClose')

const filterState = {
  open() {
    catalogFilter.classList.add('active')
    wrapper.classList.add('wrapper--filter-active')
    disableBodyScroll(catalogFilter)
  },
  close() {
    catalogFilter.classList.remove('active')
    wrapper.classList.remove('wrapper--filter-active')
    enableBodyScroll(catalogFilter)
  }
}

if (filterButton && catalogFilter && catalogFilterClose) {
  document.addEventListener(
    'click',
    evt => {
      if (evt.target.closest('#filterButton')) {
        filterState.open()
      }
    },
    false
  )
  catalogFilterClose.addEventListener('click', filterState.close, false)
}

document
  .querySelectorAll('input[data-term_slug*="assorted-"]')
  .forEach(radio => {
    const parent = radio.closest('berocket_checkbox_color')
    if (parent) {
      parent.classList.add('HIDDEN_FILTER_VALUE')
    }
  })
