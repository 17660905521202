import './home-ideas.component.scss'
import Swiper from 'swiper'
import app from '../../helpers/js/app'

const breakpoint = window.matchMedia('(max-width: 992px)')
let state = breakpoint.matches
const options = {
  slidesPerView: 4,
  spaceBetween: 20,
  navigation: {
    prevEl: '[data-nav-full] [data-prev]',
    nextEl: '[data-nav-full] [data-next]'
  },
  breakpoints: {
    992: {
      slidesPerView: 3
    },
    768: {
      slidesPerView: 2
    },
    480: {
      slidesPerView: 1,
      spaceBetween: 10
    }
  }
}

let swiperInstance

function init(options) {
  if (swiperInstance) swiperInstance.destroy()
  swiperInstance = new Swiper('.home-ideas__items', options)
  app.lazy(`.home-ideas__items .lozad-in-slider`)
}

function beforeInit() {
  let nav = {
    navigation: {
      prevEl: '[data-nav-full] [data-prev]',
      nextEl: '[data-nav-full] [data-next]'
    }
  }
  if (state) {
    nav = {
      navigation: {
        prevEl: '[data-nav-mobile] [data-prev]',
        nextEl: '[data-nav-mobile] [data-next]'
      }
    }
  }
  init({
    ...options,
    ...nav
  })
}

window.addEventListener('resize', () => {
  if (state !== breakpoint.matches) {
    state = breakpoint.matches
    beforeInit()
  }
})

beforeInit()
