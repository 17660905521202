var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;var pug_indent = [];
pug_mixins["cart-popup-item"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\n";
pug_html = pug_html + pug_indent.join("");
pug_html = pug_html + "\u003Cdiv class=\"cart-popup-item\"\u003E\n  ";
pug_html = pug_html + pug_indent.join("");
pug_html = pug_html + "\u003Cpicture class=\"cart-popup-item__image\"\u003E\u003Cimg class=\"lozad\" data-src=\"assets\u002Fimg\u002Fcart-popup-image.png\"\u003E\u003C\u002Fpicture\u003E\n  ";
pug_html = pug_html + pug_indent.join("");
pug_html = pug_html + "\u003Cdiv class=\"cart-popup-item__content\"\u003E\n    ";
pug_html = pug_html + pug_indent.join("");
pug_html = pug_html + "\u003Cdiv class=\"cart-popup-item__title\"\u003EAccent | Personalized Adult Toothbrush\u003C\u002Fdiv\u003E\n    ";
pug_html = pug_html + pug_indent.join("");
pug_html = pug_html + "\u003Cdiv class=\"cart-popup-item__props\"\u003E\u003Cspan\u003E3 case (1296)\u2028\u003C\u002Fspan\u003E\u003Cspan\u003EQty: 1\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\n    ";
pug_html = pug_html + pug_indent.join("");
pug_html = pug_html + "\u003Cdiv class=\"cart-popup-item__pricing\"\u003E\u003Cstrong\u003E$498.12\u003C\u002Fstrong\u003E\u003Cspan\u003E$0.45 each\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\n  ";
pug_html = pug_html + pug_indent.join("");
pug_html = pug_html + "\u003C\u002Fdiv\u003E\n  ";
pug_html = pug_html + pug_indent.join("");
pug_html = pug_html + "\u003Cdiv class=\"cart-popup-item__actions\"\u003E\u003Ca class=\"fas fa-pen\" href=\"#\"\u003E\u003C\u002Fa\u003E\u003Ca class=\"fas fa-trash\" href=\"#\"\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\n";
pug_html = pug_html + pug_indent.join("");
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
pug_mixins["cart-popup"] = pug_interp = function(data){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\n";
pug_html = pug_html + pug_indent.join("");
pug_html = pug_html + "\u003Cdiv class=\"cart-popup\"\u003E\u003Ca class=\"cart-popup__trigger\" href=\"\u002Fcart\u002F\" id=\"cart-popup-trigger\"\u003E\u003Cspan data-cart-count\u003E2\u003C\u002Fspan\u003E\u003Cimg class=\"lozad\" data-src=\"assets\u002Fimg\u002Fcart.svg\"\u003E\u003C\u002Fa\u003E\n  ";
pug_html = pug_html + pug_indent.join("");
pug_html = pug_html + "\u003Cdiv class=\"cart-popup__inner\" id=\"cart-popup\" data-cart-popup-state=\"closed\"\u003E\n    ";
pug_html = pug_html + pug_indent.join("");
pug_html = pug_html + "\u003Cdiv class=\"cart-popup__head\"\u003E\u003Cstrong class=\"cart-popup__title\"\u003EYour Cart\u003C\u002Fstrong\u003E\n      ";
pug_html = pug_html + pug_indent.join("");
pug_html = pug_html + "\u003Cbutton class=\"cart-popup__close\" data-cart-close\u003E\u003Ci class=\"fal fa-times\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\n    ";
pug_html = pug_html + pug_indent.join("");
pug_html = pug_html + "\u003C\u002Fdiv\u003E\n    ";
pug_html = pug_html + pug_indent.join("");
pug_html = pug_html + "\u003Cdiv class=\"cart-popup__items scroll-bar\"\u003E";
pug_indent.push('      ');
pug_mixins["cart-popup-item"]();
pug_indent.pop();
pug_indent.push('      ');
pug_mixins["cart-popup-item"]();
pug_indent.pop();
pug_html = pug_html + "\n    ";
pug_html = pug_html + pug_indent.join("");
pug_html = pug_html + "\u003C\u002Fdiv\u003E\n    ";
pug_html = pug_html + pug_indent.join("");
pug_html = pug_html + "\u003Cdiv class=\"cart-popup__foot\"\u003E\n      ";
pug_html = pug_html + pug_indent.join("");
pug_html = pug_html + "\u003Cdiv class=\"grid grid-center\"\u003E\n        ";
pug_html = pug_html + pug_indent.join("");
pug_html = pug_html + "\u003Cdiv class=\"col-6\"\u003E\n          ";
pug_html = pug_html + pug_indent.join("");
pug_html = pug_html + "\u003Clabel\u003ETotal:\u003C\u002Flabel\u003E\n        ";
pug_html = pug_html + pug_indent.join("");
pug_html = pug_html + "\u003C\u002Fdiv\u003E\n        ";
pug_html = pug_html + pug_indent.join("");
pug_html = pug_html + "\u003Cdiv class=\"col-6\"\u003E\u003Cstrong\u003E$982.45\u003C\u002Fstrong\u003E\u003C\u002Fdiv\u003E\n        ";
pug_html = pug_html + pug_indent.join("");
pug_html = pug_html + "\u003Cdiv class=\"col-6\"\u003E\u003Ca class=\"btn btn-default\" href=\"#\"\u003EView Cart\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\n        ";
pug_html = pug_html + pug_indent.join("");
pug_html = pug_html + "\u003Cdiv class=\"col-6\"\u003E\u003Ca class=\"btn btn-accent\" href=\"#\"\u003ECheckout\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\n      ";
pug_html = pug_html + pug_indent.join("");
pug_html = pug_html + "\u003C\u002Fdiv\u003E\n    ";
pug_html = pug_html + pug_indent.join("");
pug_html = pug_html + "\u003C\u002Fdiv\u003E\n  ";
pug_html = pug_html + pug_indent.join("");
pug_html = pug_html + "\u003C\u002Fdiv\u003E\n";
pug_html = pug_html + pug_indent.join("");
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};



































































;return pug_html;};
module.exports = template;