import './cats.component.scss'
import app from '../../helpers/js/app'

app.swiper('.cats__slider', {
  slidesPerView: 3,
  loopedSlides: 3,
  spaceBetween: 24,
  loop: false,
  breakpoints: {
    992: {
      loop: true,
      slidesPerView: 2,
      loopedSlides: 2,
      spaceBetween: 8
    },
    768: {
      loop: true,
      slidesPerView: 1,
      loopedSlides: 1,
      spaceBetween: 8
    }
  }
})
