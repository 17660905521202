import '../pagination/pagination.component'
import '../product-small/product-small.component'
import './catalog.component.scss'
import './modules/filter/filter.module'
let observer
let options = {
  root: null,
  rootMargin: '-200px 0px -200px 0px',
  threshold: 0
}
export function filterButtonInit() {
  if (observer) {
    observer.disconnect()
    observer = null
  }
  const products = document.querySelector('.products-list')
  const filterBtn = document.querySelector('[data-filter-button]')
  if (products && filterBtn) {
    observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        filterBtn.dataset.state = (entry.intersectionRatio > 0).toString()
      })
    }, options)
    observer.observe(products)
  }
}

filterButtonInit()
