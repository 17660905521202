// function getIndexElement(child) {
//     const parent = child.parentNode;
//     return Array.prototype.indexOf.call(parent.children, child);
// }

function setRate(stars, box) {
  let rate = parseFloat(box.dataset.rate)
  let last = rate - Math.floor(rate)
  rate = Math.floor(rate)
  let starsTemplate = ''
  for (let i = 0; i < 5; i++) {
    if (i < rate) {
      starsTemplate += stars.template()
    } else if (last) {
      starsTemplate += stars.template(last)
      last = false
    } else {
      starsTemplate += stars.template(0)
    }
  }
  box.innerHTML = starsTemplate
}

export default function(element = document) {
  const stars = {
    container() {
      return element.querySelectorAll('.rate')
    },
    template(offset = 1) {
      return `
        <div class="rate__star">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${24 *
            offset} 24" width="${24 * offset}" height="24">
            <path d="M10.71,1.55A1.33,1.33,0,0,1,12,.75a1.36,1.36,0,0,1,1.29.8L16.16,7.4l6.47,1a1.23,1.23,0,0,1,1.16.93,1.35,1.35,0,0,1-.36,1.46l-4.7,4.56,1.11,6.48a1.41,1.41,0,0,1-2,1.46l-5.81-3-5.81,3a1.22,1.22,0,0,1-1.46-.09,1.35,1.35,0,0,1-.58-1.37l1.11-6.48L.55,10.77A1.35,1.35,0,0,1,.2,9.31a1.22,1.22,0,0,1,1.15-.93l6.47-1Z"/>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill="#DAE2E4" d="M10.71,1.55A1.33,1.33,0,0,1,12,.75a1.36,1.36,0,0,1,1.29.8L16.16,7.4l6.47,1a1.23,1.23,0,0,1,1.16.93,1.35,1.35,0,0,1-.36,1.46l-4.7,4.56,1.11,6.48a1.41,1.41,0,0,1-2,1.46l-5.81-3-5.81,3a1.22,1.22,0,0,1-1.46-.09,1.35,1.35,0,0,1-.58-1.37l1.11-6.48L.55,10.77A1.35,1.35,0,0,1,.2,9.31a1.22,1.22,0,0,1,1.15-.93l6.47-1Z"/>
          </svg>
        </div>`
    }
  }

  stars.container().forEach(function(box) {
    setRate(stars, box)
  })

  // document.addEventListener('click', function (event) {
  //     const element = event.target.closest('.rate__star') ? event.target.closest('.rate__star') : (event.target.classList.contains('rate__star') ? event.target : null);
  //     if (element) {
  //         const index = getIndexElement(element);
  //         const container = element.closest('.rate-set');
  //         const box = container.querySelector('.rate');
  //         container.querySelector('input[data-rate-set-value]').value = index + 1;
  //         box.dataset.rate = index + 1;
  //         setRate(stars, box);
  //
  //     }  else {
  //         return false;
  //     }
  // })
}
