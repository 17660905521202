import './header.component.scss'
import './header.component.pug'
import '../cart-popup/cart-popup.component'
import './search'
// import {debounce} from "./debounce";
// import {throttle} from "./throttle";

const header = document.querySelector('.header')
const headerSearch = document.querySelector(
  '.header-search.header-search--desktop'
)
const wpAdminBar = document.getElementById('wpadminbar')
const initOffset = 140 / 2
const mark = document.querySelector('.intro-mark')
let eventTimeout = null

function scrollHandler(timeout) {
  const offset = document.querySelector('.intro-mark')
    ? document.querySelector('.intro-mark').offsetHeight
    : 0

  const wpAdminBarOffset = wpAdminBar ? wpAdminBar.offsetHeight : 0
  const styleTop = window.scrollY > offset ? 0 : offset - window.scrollY
  if (styleTop !== 0 || styleTop < offset) {
    header.style.top = `${
      window.scrollY > offset ? 0 : offset - window.scrollY
    }px`
    window.dispatchEvent(new Event('resize'))
  } else {
    header.style.top = `${wpAdminBarOffset}px`
  }
  const fixHeaderSearch = () => {
    const isActive = headerSearch
      .querySelector('.aws-search-form')
      .classList.contains('aws-form-active')
    search.state = isActive.toString()
  }
  if (window.scrollY > offset + initOffset) {
    if (!header.classList.contains('header--shadow')) {
      header.classList.add('header--shadow')
      mark.classList.add('intro-mark--hidden')
      fixHeaderSearch()
    }
  } else {
    if (header.classList.contains('header--shadow')) {
      header.classList.remove('header--shadow')
      mark.classList.remove('intro-mark--hidden')
      fixHeaderSearch()
    }
  }
  clearTimeout(eventTimeout)
  if (timeout !== true) {
    eventTimeout = setTimeout(() => {
      scrollHandler(true)
    }, 200)
  }
}

window.addEventListener('load', () => {
  if (header) {
    window.addEventListener('scroll', scrollHandler, false)
    scrollHandler()
  }
})

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

// menu
class menu {
  static el = document.querySelector('.header-nav')
  static header = document.querySelector('.header')
  static toggleBtn = document.querySelector('.header__menu')
  static get state() {
    return menu.el.dataset.state
  }
  static set state(state) {
    menu.el.dataset.state = state
    menu.toggleBtn.dataset.state = state
    menu.header.dataset.menuOpen = state
    if (mark) {
      mark.dataset.menuState = state
    }
  }
  static toggle() {
    if (menu.state !== 'true') {
      disableBodyScroll(menu.el)
      menu.state = 'true'
    } else {
      enableBodyScroll(menu.el)
      menu.state = 'false'
    }
  }
}

if (menu.el && menu.toggleBtn) {
  menu.toggleBtn.addEventListener('click', menu.toggle)
}

//search

class search {
  static el = document.querySelector('.header')
  static field = search.el.querySelector(
    '.header-search.header-search--desktop'
  )
  static button = search.el.querySelector(
    '.header-search.header-search--desktop .aws-search-btn.aws-form-btn'
  )
  static fieldMobile = search.el.querySelector(
    '.header-search.header-search--mobile'
  )
  static buttonMobile = search.el.querySelector('[data-action-search]')
  static get state() {
    return search.field.dataset.active
  }
  static set state(state) {
    if (state === 'false') {
      document.body.dataset.searchState = state
    }
    search.field.dataset.active = state
  }
  static get stateMobile() {
    return search.fieldMobile.dataset.state
  }
  static set stateMobile(state) {
    document.body.dataset.searchState = state
    search.fieldMobile.dataset.state = state
  }
  static buttonHandler(event) {
    if (search.state !== 'true') {
      event.preventDefault()
      search.state = 'true'
      setTimeout(() => {
        search.field.querySelector('.aws-search-field').focus()
      }, 100)
      document.addEventListener('click', search.documentClickHandler)
    }
  }
  static buttonMobileHandler(event) {
    if (search.stateMobile !== 'true') {
      event.preventDefault()
      search.stateMobile = 'true'
      setTimeout(() => {
        search.fieldMobile.querySelector('.aws-search-field').focus()
      }, 100)
      setTimeout(() => {
        document.addEventListener('click', search.documentClickMobileHandler)
      })
    }
  }
  static documentClickHandler(event) {
    if (!event.target.closest('.header-search')) {
      document.removeEventListener('click', search.documentClickHandler)
      search.state = 'false'
    }
  }
  static documentClickMobileHandler(event) {
    if (!event.target.closest('.header-search.header-search--mobile')) {
      document.removeEventListener('click', search.documentClickMobileHandler)
      search.stateMobile = 'false'
    }
  }
}

if (search.button) {
  search.button.addEventListener('click', search.buttonHandler)
}

if (search.buttonMobile) {
  search.buttonMobile.addEventListener('click', search.buttonMobileHandler)
}

import '../fancybox/fancybox.scss'
require('@fancyapps/fancybox')
$.fancybox.defaults.backFocus = false
try {
  $('[data-fancybox]').fancybox({
    buttons: ['close'],
    animationEffect: 'fade',
    hash: false
  })
  $('.youtube-link').fancybox({
    buttons: ['close'],
    animationEffect: 'fade',
    hash: false
  })
} catch (e) {}

const headerNav = document.querySelector('.header-nav')
if (headerNav) {
  headerNav.querySelectorAll('.header-nav__item').forEach(item => {
    item.addEventListener('click', event => {
      if (event.target.closest('button')) {
        item.classList.toggle('active')
      }
    })
  })
}
